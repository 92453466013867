<template lang="pug">
div
  v-btn(
    color="primary"
    outlined
    small 
    @click="onBtnClick"
  ) {{ title }}

</template>

<script>
export default {
  props: {
    ID: { type: Number, required: true},
    addMijn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['svc'],

  computed: {
    title() {
      return this.addMijn ? 'Add mijn label': 'remove Mijn label'
    }
  },

  methods: {
    async onBtnClick() {
      this.svc().updateMijnExam(this.ID, this.addMijn)
    }
  }
}
</script>